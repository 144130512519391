import './style.scss';
import {Map, View} from 'ol';
import TileLayer from 'ol/layer/Tile';
import OSM from 'ol/source/OSM';
import {fromLonLat} from 'ol/proj';
import 'bootstrap';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import {Style, Circle, Fill, Stroke} from 'ol/style';

// Istanbul coordinates (longitude, latitude)
const istanbulCoordinates = [28.9784, 41.0082];

// Function to create a marker style
function createMarkerStyle() {
  return new Style({
    image: new Circle({
      radius: 6,
      fill: new Fill({color: '#3399CC'}),
      stroke: new Stroke({color: '#fff', width: 2})
    })
  });
}

// Function to create and initialize the map
function initMap(center) {
  const markerFeature = new Feature({
    geometry: new Point(fromLonLat(center))
  });
  markerFeature.setStyle(createMarkerStyle());

  const vectorSource = new VectorSource({
    features: [markerFeature]
  });

  const vectorLayer = new VectorLayer({
    source: vectorSource
  });

  const map = new Map({
    target: 'map',
    layers: [
      new TileLayer({
        source: new OSM({
          attributions: ['© <a href="https://azat.ai" target="_blank">maps.azat.ai</a> AzatAI Maps | with OSM']
        })
      }),
      vectorLayer
    ],
    view: new View({
      center: fromLonLat(center),
      zoom: 12  // Increased zoom level for better visibility of user's location
    })
  });
  
  return map;
}

// Function to update map center and marker
function updateMapLocation(map, center) {
  const view = map.getView();
  view.setCenter(fromLonLat(center));
  
  const vectorLayer = map.getLayers().getArray().find(layer => layer instanceof VectorLayer);
  const vectorSource = vectorLayer.getSource();
  vectorSource.clear();
  
  const markerFeature = new Feature({
    geometry: new Point(fromLonLat(center))
  });
  markerFeature.setStyle(createMarkerStyle());
  vectorSource.addFeature(markerFeature);
}

// Function to get user's location
function getUserLocation() {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      reject(new Error('Geolocation is not supported by your browser'));
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          resolve([position.coords.longitude, position.coords.latitude]);
        },
        () => {
          reject(new Error('Unable to retrieve your location'));
        },
        {
          enableHighAccuracy: true,
          timeout: 5000,
          maximumAge: 0
        }
      );
    }
  });
}

// Initialize the map with Istanbul coordinates
const map = initMap(istanbulCoordinates);

// Try to get user location and update the map
getUserLocation()
  .then((userCoordinates) => {
    console.log('User location:', userCoordinates);
    updateMapLocation(map, userCoordinates);
  })
  .catch((error) => {
    console.warn('Error getting user location:', error.message);
    console.log('Using default location (Istanbul)');
  });
